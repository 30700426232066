import { Col, Row } from "react-bootstrap";
import C3Chart from "react-c3js";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SmogActionCreators } from "actions/SmogActionCreators";
import DatePicker from "react-datepicker";
import { DateTimeSecFormat, MeasurableParameter, DateFormatDatePicker } from "constants/Constants";
import { IDeviceSmogData } from "constants/SmogConstants";
import "../../../less/Chart.css";
import "../../../less/ChartSmog.css";
import { IAppStore } from "store/configureStore";
import Spinner from "../../Spinner";
import * as React from "react";
import * as moment from 'moment';

interface IDeviceParameterSmogChartProps {
    deviceId: number,
    smogData: IDeviceSmogData,
    message: string,
    fetchData: (deviceId: number, from: Date, to: Date) => void;
}

const sensors: { [id: string]: number; } = {};
sensors["temperature"] = 2;
sensors["pm25"] = 5;
sensors["pm10"] = 6;

const getStartDate = (data: IDeviceSmogData): Date => {
    if (data == null || (data.records || []).length === 0)
        return null;
    return moment.utc(data.records[data.records.length - 1].date).toDate();
}

const getEndDate = (data: IDeviceSmogData): Date => {
    if (data == null || (data.records || []).length === 0)
        return null;
    return moment.utc(data.records[0].date).toDate();
}

const DeviceParameterSmogChart = (props: IDeviceParameterSmogChartProps) => {
    const intl = useIntl();
    const [parameterId, setParameter] = React.useState<number>(null);
    const [startDate, setStartDate] = React.useState<Date>(getStartDate(props.smogData));
    const [endDate, setEndDate] = React.useState<Date>(getEndDate(props.smogData));

    React.useEffect(() => {
        props.fetchData(props.deviceId, startDate, endDate);
    }, []);

    React.useEffect(() => {
        const sDate = getStartDate(props.smogData);
        const eDate = getEndDate(props.smogData);
        if (sDate !== null && eDate !== null) {
            setStartDate(sDate);
            setEndDate(eDate);
        }
    }, [props]);

    if (props.smogData === null || props.message === "Loading")
        return (<Spinner />);

    if (parameterId)
        return (< Navigate to={`/device/${props.deviceId}/${MeasurableParameter[parameterId]}`} />);

    const legend = {
        item: {
            onclick: (name: any) => {
                setParameter(sensors[name]);
            }
        }
    };

    let emptyObject = null;
    if ((props.smogData.records || []).length === 0)
        emptyObject = {
            label: { text: intl.formatMessage({ id: "device.nodata" }) }
        };

    const data = {
        x: 'x',
        xFormat: "%Y-%m-%d %H:%M:%S",
        columns: [
            ['x'].concat(props.smogData.records.map(r => moment.utc(r.date).local().format(DateTimeSecFormat))),
            ['temperature' as any].concat(props.smogData.records.map(r => r.temperature)),
            ['pm25' as any].concat(props.smogData.records.map(r => r.pm25)),
            ['pm10' as any].concat(props.smogData.records.map(r => r.pm10))
        ],
        types: {
            pm25: 'area',
            pm10: 'area',
            temperature: 'line'
        },
        colors: {
            pm25: '#1f77b4',
            pm10: '#aec7e8',
            temperature: '#ff7f0e'
        },
        axes: {
            pm25: 'y',
            pm10: 'y',
            temperature: 'y2'
        },
        groups: [['pm25', 'pm10']],
        names: {
            temperature: intl.formatMessage({ id: "device.chart.parameter_2" }),
            pm25: intl.formatMessage({ id: "device.chart.parameter_5" }),
            pm10: intl.formatMessage({ id: "device.chart.parameter_6" })
        },
        empty: emptyObject
    };

    const axis = {
        x: {
            type: 'timeseries',
            tick: {
                count: 3,
                format: '%Y-%m-%d %H:%M'
            }
        },
        y: {
            label: {
                text: intl.formatMessage({ id: "device.chart.parameter.unit_5" }),
                position: 'outer-middle'
            }
        },
        y2: {
            show: true,
            label: {
                text: intl.formatMessage({ id: "device.chart.parameter.unit_2" }),
                position: 'outer-middle'
            },
            tick: {
                format: (x: number) => x.toFixed(1),
            }
        }
    };
    const grid = {
        y: {
            lines: [
                { value: props.smogData.pm25Norm, text: intl.formatMessage({ id: "device.chart.parameter_5" }) },
                { value: props.smogData.pm10Norm, text: intl.formatMessage({ id: "device.chart.parameter_6" }) },
            ]
        }
    };

    return (
        <Row>
            <Col className="device-parameter-smog-chart">
                <div className="chart-settings">

                    {(startDate !== null && endDate !== null)
                        ? (<div className="dates">
                            <DatePicker
                                selected={startDate}
                                onChange={date => props.fetchData(props.deviceId, date as Date, endDate)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={endDate}
                                className="date-input"
                                dateFormat={DateFormatDatePicker}
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={date => props.fetchData(props.deviceId, startDate, date as Date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                className="date-input"
                                dateFormat={DateFormatDatePicker}
                            />
                        </div>)
                        : null
                    }
                </div>
                <div className="chart">
                    <C3Chart
                        data={data}
                        axis={axis}
                        grid={grid}
                        legend={legend}
                    />
                </div>
            </Col>
        </Row>);
};


function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        smogData: state.deviceSmog.data,
        message: state.deviceSmog.message,
        deviceId: ownProps.deviceId,
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(SmogActionCreators, dispatch)
)(DeviceParameterSmogChart);
