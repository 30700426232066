
import {useIntl} from "react-intl";
import * as React from "react";

export enum ShopLinkType {
    PremiumPackage,
    DataTransfer
}

const ShopLink = (
    props: {
        type: ShopLinkType
    }) => {
    const intl = useIntl();

    let linkMap: Record<ShopLinkType, [string, string]> = {
        [ShopLinkType.DataTransfer]: ["https://termipol.pl/basket/add/216/1", "device.edit.data-transfer.basket"], //TODO: Get correct link
        [ShopLinkType.PremiumPackage]: ["https://termipol.pl/basket/add/216/1", "device.edit.premium-package.basket"]
    }

    return (
        <a href={linkMap[props.type][0]} target="_blank"
           className="shop-link"
           style={{color: "inherit", textDecoration: "inherit"}}
           title={intl.formatMessage({id: linkMap[props.type][1]})}>
            <i className="material-icons">shopping_basket</i>
        </a>
    );
}

export default ShopLink;
