import { useState, useEffect } from 'react';
import {IPlannerDevice, IPlannerTab, PlannerDeviceStatus} from "../constants/PlannerDeviceConstants";
import {promiseResult} from "../actions/promiseDispatch";
import FloorPlannerAPI from "../api/FloorPlannerAPI";

 export function useSpacePlannerDevices(): [
     IPlannerDevice[],
     (devices: IPlannerDevice[]) => void,
     (deviceId: number) => void
 ] {
    const [devices, setDevices] = useState<IPlannerDevice[]>([]);
   
     function removeDevice(deviceId: number) {
         setDevices(devices.filter(d => d.deviceId != deviceId));
     }
     function addDevices(newDevices: IPlannerDevice[]) {
         setDevices(devices.concat(newDevices));
     }

     useEffect(() => {
         promiseResult<IPlannerDevice[]>(
             FloorPlannerAPI.getDevices,
             (result: IPlannerDevice[]) => {
                 setDevices(result);
             },
             () => {
                 console.log("error");
             }
         );
     }, []);

    return [devices, addDevices, removeDevice];
}