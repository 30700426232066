import * as React from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import '../less/App.css';
import About from "./About/About";
import ConfirmEmail from "./Account/ConfirmEmail";
import SubscribeNotificationEmail from "./Notification/SubscribeNotificationEmail";
import UnSubscribeNotificationEmail from "./Notification/UnSubscribeNotificationEmail";
import ForgotPassword from "./Account/ForgotPassword";
import Login from "./Account/Login";
import Registration from "./Account/Registration";
import ResetPassword from "./Account/ResetPassword";
import Contact from "./Dashboard/Contact";
import Dashboard from "./Dashboard/Dashboard";
import Device from "./Device/Device";
import Logo from "./Logo";
import MapComponent from "./Map/MapComponent";
import Notification from "./Notification/Notification";
import Portal from "./Portal";
import RegisterDeviceContainer from "./RegisterDevice/RegisterDeviceContainer";
import SiteLeftPanel from "./SiteLeftPanel";
import SiteLeftPanelSmall from "./SiteLeftPanelSmall";
import TopMenu from "./TopMenu";
import TosCharges from "./Tos/Charges";
import TosComplaintTemplate from "./Tos/ComplaintTemplate";
import TosConsumerRights from "./Tos/ConsumerRights";
import TosIndex from "./Tos/Index";
import TosPrivacy from "./Tos/Privacy";
import TosRenouncement from "./Tos/Renouncement";
import TosTechnicalBreak from "./Tos/TechnicalBreak";
import TosThreats from "./Tos/Threats";
import UserContext, { IUserContext } from "./UserContext";
import Manage from "./UserSettings/Manage";
import ExternalLogin from "./Account/ExternalLogin";
import Supervisor from "components/Supervisor/Supervisor";
import DemoLogin from "./Account/DemoLogin";
import SpacePlanner from "./SpacePlanner/SpacePlanner";

const Site = () => {
    const userContext = React.useContext<IUserContext>(UserContext);
    return (
            <Routes>
            {(userContext.isAuthenticated && userContext.isEmailConfirmed)
                    ? <Route path="/" element={<Navigate to="/main" />}/>
                    : <Route path="/" element={<Navigate to="/login" />}/>
            }
                <Route path="/portal" element={<Portal/>} />
                <Route path="/main" element={<MenuWrapper><Dashboard/></MenuWrapper>} />
                <Route path="/login" element={<Login/>} />

                {(userContext.demoUserEnabled
                    ? <Route path="/login-demo" element={<DemoLogin/>} />
                    :null)}

                {(!userContext.isDemoUser) &&
                   <>
                       <Route path="/registration" element={<Registration/>} />
                       <Route path="/forgotpassword" element={<ForgotPassword/>} />
                       <Route path="/confirmemail" element={<ConfirmEmail/>} />
                       <Route path="/subscribe-email" element={<SubscribeNotificationEmail/>} />
                       <Route path="/unsubscribe-email" element={<UnSubscribeNotificationEmail/>} />
                       <Route path="/resetpassword" element={<ResetPassword/>} />
                       <Route path="/externallogin" element={<ExternalLogin/>} />
                   </>
                }

                <Route path="/register-device" element={<MenuWrapper><RegisterDeviceContainer/></MenuWrapper>} />
                <Route path="/contact" element={<MenuWrapper><Contact/></MenuWrapper>} />
                <Route path="/about" element={<MenuWrapper><About/></MenuWrapper>} />
                <Route path="/space-planner" element={<MenuWrapper><SpacePlanner /></MenuWrapper>} />
                <Route path="/manage" element={<MenuWrapper><Manage/></MenuWrapper>} />
                <Route path="/tos" element={<MenuWrapper><TosIndex/></MenuWrapper>} />
                <Route path="/tos/charges" element={<MenuWrapper><TosCharges/></MenuWrapper>}  />
                <Route path="/tos/complaint-template" element={<MenuWrapper><TosComplaintTemplate/></MenuWrapper>}/>
                <Route path="/tos/consumer-rights" element={<MenuWrapper><TosConsumerRights/></MenuWrapper>} />
                <Route path="/tos/privacy"  element={<MenuWrapper><TosPrivacy/></MenuWrapper>} />
                <Route path="/tos/renouncement" element={<MenuWrapper><TosRenouncement/></MenuWrapper>} />
                <Route path="/tos/sla" element={<MenuWrapper><TosTechnicalBreak/></MenuWrapper>}  />
                <Route path="/tos/threats" element={<MenuWrapper><TosThreats/></MenuWrapper>} />
                <Route path="/notifications" element={<MenuWrapper><Notification/></MenuWrapper>} />

                <Route path="/device/:deviceId" element={<MenuWrapper><Device /></MenuWrapper>} >
                    <Route path=":parameterName" element={<MenuWrapper><Device /></MenuWrapper>} />
                </Route>
                {(userContext.smogMapEnabled || userContext.isAuthenticated) &&
                    <Route path="/map" element={<MenuWrapper> <MapComponent/> </MenuWrapper>} />
                }
                {(userContext.isSupervisor) &&
                    <Route path="/supervisor" element={<MenuWrapper><Supervisor/></MenuWrapper>}/>
                }
            </Routes>
        );
}

const MenuWrapper = (props: any) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    if (userContext.isAuthenticated)
        return (<AuthenticatedLayout>{props.children}</AuthenticatedLayout>);
    return (<AnonymousLayout>{props.children}</AnonymousLayout>);
}

const AuthenticatedLayout = (props: any) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        <div className="dashboard-container">
            <div className={"main-container " + userContext.style}>
                <div className={"left-panel " + (userContext.isLeftMenuExpanded ? "col-sm-2" : "sidebar-left")}>
                    <Logo />
                    {(userContext.isLeftMenuExpanded
                    ? <SiteLeftPanel />
                    : <SiteLeftPanelSmall />)}
                </div>
                <div className={"right-panel " + (userContext.isLeftMenuExpanded ? "col-sm-10" : "sidebar-right")}>
                    <TopMenu />
                    {props.children}
                </div>
            </div>
        </div>
    );
}

const AnonymousLayout = (props: any) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const style = userContext && userContext.style ? userContext.style : "standard";
    return (
        <div className="dashboard-container">
            <div className={"main-container " + style}>
                <div className="right-panel col-sm-12">
                    <TopMenu />
                    {props.children}
                </div>
            </div>
        </div>);
}

export default Site;