import * as React from "react";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollArea from "react-scrollbar";
import { bindActionCreators } from "redux";
import { DeviceActionCreators } from "actions/DeviceActionCreators";
import { MeasurableParameter } from "constants/Constants";
import "../../less/Device.css";
import DeviceData from './DeviceData';
import DeviceDetails from './DeviceDetails/DeviceDetails';
import DeviceParameters from './DeviceParameters';


interface IDeviceProps {
    changeDate: (dateStart: Date, dateEnd: Date) => void;
  }

const Device = (props: IDeviceProps) => {
    const params = useParams<Record<string,string>>();
    const deviceId = (params["deviceId"] ?? 0) as number;
    const parameterName = params["parameterName"] as keyof typeof MeasurableParameter;
    const parameterId = params["parameterName"] ? MeasurableParameter[parameterName] : null;

    useEffect(() => {
        props.changeDate(null,null);
    }, [deviceId]);
    
    return (
        <ScrollArea className="scrollarea-device device" style={{ width: "100%" }} >
            <DeviceDetails deviceId={deviceId} />
            <DeviceParameters
                deviceId={deviceId}
                selectedParameterId={parameterId}
            />
            <DeviceData
                deviceId={deviceId}
                selectedParameterId={parameterId}
                />
        </ScrollArea>
    );
}

export default connect(
    null,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(Device);
