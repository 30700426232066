import * as React from "react";
import {Row, Col} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {useIntl} from "react-intl";
import {MeasurableParameter, DateFormat, AccessMode} from "constants/Constants";
import {OperationModeMap, IsSmogDevice} from "constants/Common";
import DeviceDetailsSettings from "./DeviceDetailsSettings";
import DeviceDetailsSampling from "./DeviceDetailsSampling";
import ChangeStateSign from "./ChangeStateSign";
import ParameterName from "components/Device/ParameterName";
import {IDeviceDetails, IDeviceParameter} from "constants/DeviceConstants";
import ShopLink, {ShopLinkType} from "./ShopLink";

const IsOperatingModeVisible = (device: IDeviceDetails) => (device.parameters || []).filter(p => p != null && p.parameterId === MeasurableParameter.Level).length > 0;

const GetCustomMinValue = (parameter: IDeviceParameter) =>
    parameter.customMinValue > parameter.customMaxValue
        ? parameter.customMaxValue
        : parameter.customMinValue;
const GetCustomMaxValue = (parameter: IDeviceParameter) =>
    parameter.customMaxValue < parameter.customMinValue
        ? parameter.customMinValue
        : parameter.customMaxValue;
const DeviceDetailsView = (
    props: {
        device: IDeviceDetails,
        editDeviceForm: () => void,
    }
) => {
    const intl = useIntl();
    const device = props.device;
    const isSmogDevice = IsSmogDevice(device.parameters);
    const widgetLink = "/widgets/v1.0/" + (isSmogDevice ? "smog" : "device") + "?publicKey=" + props.device.publicToken;
    const showOperationMode = (): JSX.Element => {
        return (IsOperatingModeVisible(device) ?
            <Col xs={12} md={3} sm={4}>
                <span><FormattedMessage id="device.edit.operatingMode"/>&nbsp;</span>
                <span>
                    {(device.previousOperatingMode === null ?
                            <FormattedMessage id={OperationModeMap(device.operatingMode)}/>
                            : <span className="new-sampling"><FormattedMessage
                                id={OperationModeMap(device.operatingMode)}/></span>
                    )}
                </span>
                <span className="sampling">
                    <ChangeStateSign hide={device.previousOperatingMode === null}/>
                </span>
            </Col> : null);
    }

    return (
        <div className="device-details">
            <DeviceDetailsSettings
                isEditable={props.device.accessMode === AccessMode.Administrator}
                editDeviceForm={props.editDeviceForm}
            />
            <Row className="show-grid">
                <Col xs={12} md={3} sm={4}>
                    <span> <FormattedMessage id="device.edit.name"/>  </span> <span>{device.name}</span>
                </Col>
                <DeviceDetailsSampling device={device}/>
                {showOperationMode()}
                <Col xs={12} md={3} sm={4}>
                    <span><FormattedMessage id="device.edit.key"/></span> <span>{device.key}</span>
                </Col>
                <Col xs={12} md={3} sm={4}>
                    <span><FormattedMessage id="device.edit.subscription"/></span> &nbsp;
                    <span
                        title={`${intl.formatMessage({id: "device.edit.subscription.limit"})}: ${device.subscriptionLimit}`}>
                        {device.subscriptionName} ({device.subscriptionLimit})
                        {device.subscriptionName != "Premium" 
                            ? <ShopLink type={ ShopLinkType.PremiumPackage } />
                            : null}
                    </span>
                </Col>
                <Col xs={12} md={3} sm={4}>
                    <span><FormattedMessage id="device.edit.disconnected-alert"/></span>
                    <span title={intl.formatMessage({id: "device.edit.disconnected-alert.details"})}
                          className="disconnected-alert"> &nbsp; <i className="material-icons">info</i>
                        <span>{device.disconnectedAlert ? <FormattedMessage id="on"/> :
                            <FormattedMessage id="off"/>} </span> </span>
                </Col>
                {device.subscriptionExpirationDate ?
                    <Col xs={12} md={3} sm={4}>
                        <span><FormattedMessage id="device.edit.subscription.expiration"/></span> &nbsp;
                        <span>{moment.utc(device.subscriptionExpirationDate).local().format(DateFormat)}</span>
                    </Col> : null
                }
                {device.dataTransferExpirationDate ?
                    <Col xs={12} md={3} sm={4}>
                        <span><FormattedMessage id="device.edit.data-transfer.expiration"/></span>
                        &nbsp;
                        <span className="data-transfer">
                            {moment.utc(device.dataTransferExpirationDate).local().format(DateFormat)}
                            <ShopLink type={ ShopLinkType.DataTransfer } />
                            </span>
                    </Col> : null
                }
                {
                    (device.parameters || []).filter(p => p.parameterId === 1).map(p =>
                        <Col xs={12} md={3} sm={4} key="batter.alert">
                            <span><FormattedMessage id="device.edit.threshold.battery"/></span>
                            <span>{(p.thresholdMin > p.minValue ? <FormattedMessage id="on"/> :
                                <FormattedMessage id="off"/>)}</span>
                        </Col>
                    )
                }
                {
                    (device.parameters || []).filter(p => p.parameterId !== 1).map(p =>
                        <Col xs={12} md={3} sm={4} key={`threshold_min_${p.parameterId}`}>
                            <span><FormattedMessage id="device.edit.min"/>&nbsp;
                                <ParameterName parameter={p}/>
                                </span> <span>{p.thresholdMin ?? GetCustomMinValue(p) ?? p.minValue}{p.unit}</span>
                        </Col>
                    )
                }
                {
                    (device.parameters || []).filter(p => p.parameterId !== 1).map(p =>
                        <Col xs={12} md={3} sm={4} key={`threshold_max_${p.parameterId}`}>
                            <span> <FormattedMessage id="device.edit.max"/>&nbsp;
                                <ParameterName parameter={p}/>
                            </span> <span>{p.thresholdMax ?? GetCustomMaxValue(p) ?? p.maxValue}{p.unit}</span>
                        </Col>
                    )
                }
                {device.batteryLevel ?
                    <Col xs={12} md={3} sm={4}>
                        <span><FormattedMessage id="device.edit.battery"/></span>
                        <span>&nbsp;{`${device.batteryLevel}%`}</span>
                    </Col> : null}
                {props.device.publicData
                    ?
                    <React.Fragment>
                        <Col xs={12} md={3} sm={4}>
                            <span><FormattedMessage id="device.edit.public.link"/></span>
                            <span className="public-api-link">
                                &nbsp;
                                <a target="_blank"
                                   href={"/public-api/v1.0/devices/" + props.device.publicToken + "/data?date=" + (new Date().toISOString().slice(0, 10))}
                                   title="API">
                                    <span className="material-icons">content_copy</span>
                                </a>

                            </span>
                        </Col>
                        <Col xs={12} md={3} sm={4}>
                            <span><FormattedMessage id="device.edit.public.widget"/></span>
                            <span className="public-api-link">
                                &nbsp;
                                <a target="_blank" href={widgetLink} title="Widget">
                                    <span className="material-icons">content_copy</span>
                                </a>
                            </span>
                        </Col>
                    </React.Fragment>
                    : null}
            </Row>
        </div>
    );
}

export default DeviceDetailsView;