import * as React from "react";
import UserContext, {IUserContext} from "./UserContext";
import {Button} from "react-bootstrap";
import {useIntl} from "react-intl";
import {ButtonVariant} from "react-bootstrap/types";

export interface ISubmitButtonProps {
    disabled?: boolean;
    titleId?: string;
    children?: React.ReactNode;
    onClick?: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
    className? : string;
    variant?: ButtonVariant;
}

const SubmitButton = (props: ISubmitButtonProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const intl = useIntl();
    return (
        <Button variant={ props.variant ?? "primary" } className={(props.className)} type="submit"
                disabled={props.disabled || userContext.isDemoUser}
                title={userContext.isDemoUser
                    ? intl.formatMessage({id: "common.submit.button.disabled.demo"})
                    :  (props.titleId ?  intl.formatMessage({id: props.titleId }) : null)}
                onClick={props.onClick}>
            {props.children}
        </Button>
    );
}

export default SubmitButton;