import * as React from "react";
import { useEffect } from "react";
import "react-input-range/lib/css/index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceActionCreators } from "actions/DeviceActionCreators";
import { EditDeviceStatus, IParameterSettings, ModeDeviceDetailsView } from "constants/Constants";
import "../../../less/DeviceDetails.css";
import { IAppStore } from "store/configureStore";
import DeviceDetailsEdit from "./DeviceDetailsEdit";
import DeviceDetailsView from "./DeviceDetailsView";
import { IEditedDevice, IDeviceDetails } from "constants/DeviceConstants";

interface IDeviceDetailsProps {
    message: string;
    deviceId: number;
    editForm: IEditedDevice;
    device: IDeviceDetails;
    mode: ModeDeviceDetailsView;
    editDataStatus: EditDeviceStatus;
    editDeviceForm: (editedForm: IEditedDevice) => void;
    changeModeView: (mode: ModeDeviceDetailsView) => void;
    saveDeviceDetails: (model: IEditedDevice) => void;
    cancelEditing: () => void;
    fetchSingleDevice: (deviceId: number) => void;
}

const DeviceDetails = (props: IDeviceDetailsProps) => {
    const edit = () => {
        return (<DeviceDetailsEdit
            device={props.device}
            editForm={props.editForm}
            editDeviceForm={props.editDeviceForm}
            cancelEditing={props.cancelEditing}
            saveDeviceDetails={props.saveDeviceDetails}
        />);
    }
    const editDeviceForm = () => {
        props.editDeviceForm(
            {
                deviceId: props.device.deviceId,
                name: props.device.name,
                sampling: props.device.sampling,
                operatingMode: props.device.operatingMode,
                disconnectedAlert: props.device.disconnectedAlert,
                latitude: (props.device.latitude || "").toString(),
                longitude: (props.device.longitude || "").toString(),
                publicData: props.device.publicData,
                parameterSettings: (props.device.parameters || []).map(p => {
                    return {
                        parameterId: p.parameterId,
                        maxValue: p.maxValue,
                        minValue: p.minValue,
                        unit: p.unit,
                        thresholdMax: p.thresholdMax,
                        thresholdMin: p.thresholdMin,
                        customName: p.customName,
                        customMinValue: p.customMinValue,
                        customMaxValue: p.customMaxValue,
                        offset: p.offset,
                        customUnit: p.customUnit,
                        shifter: p.shifter,
                        showInDashboardTile: p.showInDashboardTile,
                        converter: p.converter
                    } as IParameterSettings
                })
            });
    }
    const view = (): JSX.Element => {
        return (<DeviceDetailsView
            device={props.device}
            editDeviceForm={editDeviceForm}
        />);
    }


    useEffect(() => {
        props.fetchSingleDevice(props.deviceId);
    }, [props.deviceId]);
    
    return props.editDataStatus === "saving" || props.message === "loading"
        ? null
        : props.mode === "Edit"
            ? edit()
            : (props.device ? view() : null)
}

function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        message: state.deviceDetails.message,
        editForm: state.deviceDetails.editedDevice,
        device: state.deviceDetails.device,
        editDataStatus: state.deviceDetails.editDeviceStatus,
        mode: state.deviceDetails.mode,
        deviceId: ownProps.deviceId
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(DeviceDetails);