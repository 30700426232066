import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ModeDeviceDetailsView } from "constants/Constants";
import { FormattedMessage } from "react-intl";
import ActivationCodeModal from "../../ActivationCode/ActivationCodeModal";


const DeviceDetailsSettings = (
    props: {
        isEditable: boolean,
        editDeviceForm: () => void,
    }
) => {   
    return (
        <Row className="show-grid">
            <Col md={6}>
                {
                    props.isEditable ?
                        <Button onClick={props.editDeviceForm}>
                            <i className="material-icons">build</i> <FormattedMessage id="device.edit.settings" />
                        </Button>
                        : null
                }
            </Col>
            <Col md={6} style={{textAlign:"right"}}>
                <ActivationCodeModal/>
            </Col>
        </Row>
    );
}

export default DeviceDetailsSettings;