import * as React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceActionCreators } from "../../../actions/DeviceActionCreators";
import "../../../less/DeviceReset.css";
import { IAppStore } from "../../../store/configureStore";
import UserContext, { IUserContext } from "../../UserContext";
import { useNavigate } from 'react-router-dom';
import SubmitButton from "../../SubmitButton";

export interface IDeviceResetProps {
    deviceId: number;
    showClearDataFormDialog: boolean;
    showUnregisterDeviceFormDialog: boolean;
    showHideDeleteDataDialog: (show: boolean) => void;
    showHideDeleteDeviceDialog: (show: boolean) => void;
    deleteDeviceData: (deviceId: number) => void;
    deleteDevice: (deviceId: number) => void;
    cleanDeviceParameters: () => void;
}

const DeviceResetContainer = (props: IDeviceResetProps) => {
    let navigate = useNavigate();
    const cleanDeviceData = () => {
        props.showHideDeleteDataDialog(false);
        props.deleteDeviceData(props.deviceId);
        props.cleanDeviceParameters();
    }

    const deleteDevice = () => {
       props.showHideDeleteDeviceDialog(false);
       props.deleteDevice(props.deviceId);
       navigate('/');
    }

    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        <div className="device-reset">
            <div className="content">
                <Row className="actions">
                    <Col xs={12} md={6} sm={6}>
                        <Button onClick={props.showHideDeleteDataDialog.bind(this, true)}>
                            <i className="material-icons">delete_forever</i><FormattedMessage id="device.edit.remove" />
                        </Button>
                        <div className="description"> <FormattedMessage id="device.reset.clear.data" /> </div>
                    </Col>
                    <Col xs={12} md={6} sm={6}>
                        <Button onClick={props.showHideDeleteDeviceDialog.bind(this, true)}>
                            <i className="material-icons">delete</i> <FormattedMessage id="device.edit.unregister" />
                        </Button>
                        <div className="description"> <FormattedMessage id="device.reset.remove.device" /> </div>
                    </Col>
                </Row>
            </div>
            {
                props.showClearDataFormDialog ?
                    <Modal centered show={true} className={"reset-confirm-modal " + userContext.style}>
                        <Modal.Header>
                            <Modal.Title><FormattedMessage id="common.box.confirm" /></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="device.edit.remove.question" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.showHideDeleteDataDialog.bind(this, false)}>
                                <FormattedMessage id="device.edit.cancel" />
                            </Button>
                            <SubmitButton onClick={cleanDeviceData}>
                                <FormattedMessage id="device.edit.remove.btn" />
                            </SubmitButton>
                        </Modal.Footer>
                    </Modal> : null
            }
            {
                props.showUnregisterDeviceFormDialog ?
                    <Modal centered show={true} className={"reset-confirm-modal " + userContext.style}>
                        <Modal.Header>
                            <Modal.Title><FormattedMessage id="common.box.confirm" /></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="device.reset.remove.device.confirm" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.showHideDeleteDeviceDialog.bind(this, false)}>
                                <FormattedMessage id="device.edit.cancel" />
                            </Button>
                            <SubmitButton onClick={deleteDevice}>
                                <FormattedMessage id="device.edit.remove.btn" />
                            </SubmitButton>
                        </Modal.Footer>
                    </Modal> : null
            }
        </div>
    );
}

function mapStateToProps(state: IAppStore) {
    return {
        deviceId: state.deviceDetails.device.deviceId,
        showClearDataFormDialog: state.deviceDetails.showClearDataFormDialog,
        showUnregisterDeviceFormDialog: state.deviceDetails.showUnregisterDeviceFormDialog
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(DeviceResetContainer);