export const RefreshTimeoutMs: number = 60000; // 60s
export const DateTimeSecFormat: string = "YYYY-MM-DD HH:mm:ss";
export const DateTimeFormat: string = "YYYY-MM-DD HH:mm";
export const DateFormat: string = "YYYY-MM-DD";
export const DateFormatDatePicker: string = "yyyy-MM-dd";

export enum MeasurableParameter {
    Battery = 1, 
    Temperature = 2,
    Humidity = 3,
    Level = 4,
    Pm25 = 5,
    Pm10 = 6,
    Temperature2 = 7,
    Temperature3 = 8,
    Voltage = 9,
    Current = 10,
    Power = 11,
    Voltage2 = 12,
    Voltage3 = 13,
    Current2 = 14,
    Power2 = 15,
    Distance = 16,
    Location = 101,
}

export interface IDeviceTile {
    deviceId: number;
    name: string;
    values: IParameterValue[];
    lastUpdate: Date;
    batteryLevel: number;
    isOnline?: boolean;
    subscriptionTypeId: number;
    qualityIndex: number;
    deviceParameters: MeasurableParameter[];
    deviceParameterNames: IParameterName[];
}

export interface  IParameterName {
    parameterName: string,
    unit?: string,
    parameterId: MeasurableParameter
}

export enum AlertLevel
{
    Above = 1,
    Below = -1,
    Inside = 0
}

export interface IParameterValue {
    parameterId: MeasurableParameter,
    unit?: string,
    value?: number;
    alert: AlertLevel;
}

export interface IActionType {
    request: string;
    success: string;
    failure: string;
}

export interface IParameterSettings {
    maxValue: number,
    minValue: number,
    customMaxValue: number,
    customMinValue: number,
    unit: string,
    parameterId: number;
    thresholdMax: number;
    thresholdMin: number;
    offset: number;
    customName: string;
    customUnit: string;
    converter: number;
    shifter: number;
    showInDashboardTile: boolean;
}

export type EditDeviceStatus = "saving" | "saved" | "failed";
export type ModeDeviceDetailsView = "View" | "Edit";
export type ModeDeviceParametersView = "Collapse" | "Expand";
export type ValidationState = "success" | "error" | "warning";
export type DashboardMode = "tiles" | "list" | "grid" | null;

export const jsonHeader =  {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export enum Sampling {
    Sec30 = 30,
    Sec60 = 60,
    Sec900 = 900,
    Sec1800 = 1800,
    Sec3600 = 3600,
    Hour12 = 43200,
    Hour24 = 86400
}

export enum OperatingMode {
    Standard = 0,
    Control = 1
}

export enum AccessMode {
    Administrator = 1,
    Readonly = 2,
    Supervisor = 3
}

export class NavigationID {
    public static readonly LOCATION = "LOCATION";
}

export class DashboardActions {
    public static readonly FETCH_DASHBOARD = "FETCH_DASHBOARD";
    public static readonly FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
    public static readonly FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED";
    public static readonly CHANGE_DASHBOARD_MODE = "CHANGE_DASHBOARD_MODE";
    public static readonly STORE_DASHBOARD_MODE = "STORE_DASHBOARD_MODE";
    public static readonly STORE_DASHBOARD_MODE_SUCCESS = "STORE_DASHBOARD_MODE_SUCCESS";
    public static readonly STORE_DASHBOARD_MODE_FAILED = "STORE_DASHBOARD_MODE_FAILED";
    public static readonly STORE_DEVICES_ORDER = "STORE_DEVICES_ORDER_MODE";
    public static readonly STORE_DEVICES_ORDER_SUCCESS = "STORE_DEVICES_ORDER_SUCCESS";
    public static readonly STORE_DEVICES_ORDER_FAILED = "STORE_DEVICES_ORDER_FAILED";


}

export class RegisterDeviceAction {
    public static readonly EDIT_KEY = "REGISTER_DEVICE_EDIT_KEY";
    public static readonly INVALID_KEY = "REGISTER_DEVICE_INVALID_KEY";
    public static readonly INVALID_NAME = "REGISTER_DEVICE_INVALID_NAME";
    public static readonly EDIT_NAME = "REGISTER_DEVICE_EDIT_NAME";
    public static readonly CLEAR = "REGISTER_DEVICE_CLEAR";
    public static readonly SUBMIT = "REGISTER_DEVICE_SUBMIT";
    public static readonly SUBMIT_SUCCESS = "REGISTER_DEVICE_SUBMIT_SUCCESS";
    public static readonly SUBMIT_FAILED = "REGISTER_DEVICE_SUBMIT_FAILED";
}

export interface IRegisterDeviceForm {
    key: string;
    name: string;
}

export interface IRegisterDeviceResponse {
    isSaved: boolean;
    keyError: string;
    nameError: string;
}

export interface IResult {
    isSuccessed: boolean;
    message: string;
    messageValues: Record<string, any>
}

export interface IEmailResult extends IResult{
    email: string;
}
